import axios from "axios";
import Vue from "vue";

import Router from "@/router/index";

// console.log("base_url is " + process.env.VUE_APP_API);

const url_host = "https://api.hbzhengwu.com/yuedu/api/"

const service = axios.create({
  baseURL: url_host,
  timeout: 60 * 1000, // 请求超时时间,
  headers: {
    "Content-Type": "application/json",
  },
});

service.interceptors.request.use(
  (config) => {
    var token = window.localStorage.getItem("token");
    if (token && token != "" && token != "undefined") {
      config.headers.token = token;
    }
    if (config.url == "profile/upload")
      config.headers = {
        ...config.headers,
        "Content-Type": "multipart/form-data",
      };
    // config.headers.api = "tujkkakkxkak";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use((response) => {
  if (response.status == 200) {
    if (response.data.code == 20000 && response.data != null) {
      return Promise.resolve(response.data.data);
    } else {
      switch (response.data.code) {
        case 10001:
          Vue.toast("请重新登录");
          //   Store.dispatch("logout");
          Router.replace({
            path: "/",
          });
          break;
        case 30001:
          Vue.toast(response.data.message);
          setTimeout(() => {
            Router.replace({
              path: "/",
            });
          }, 1000);
          break;
        default:
          break;
      }
      return Promise.reject(response.data.message);
    }
  } else {
    return Promise.reject(response.errMsg);
  }
});

export function get(url, params) {
  return new Promise((resolve, reject) => {
    service
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((msg) => {
        reject(msg);
      });
  });
}

export function post(url, params) {
  return new Promise((resolve, reject) => {
    service
      .post(url, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function upload(url, data) {
  return new Promise((resolve, reject) => {
    service
      .post(url, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}