import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Tools from './untils/tools'
// 引入所有组件
import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);

Vue.config.productionTip = false;
Vue.use({
  install(Vue, options) {
    Vue.prototype.decodeContent = Tools.decodeContent
  }
})

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");