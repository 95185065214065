import {
    Base64
} from "js-base64";

function decodeContent(content = "") {
    return Base64.decode(content)
}

export default {
    decodeContent,
}